import { render, staticRenderFns } from "./RedactarCartas.vue?vue&type=template&id=7784f6ef&scoped=true"
import script from "./RedactarCartas.vue?vue&type=script&lang=js"
export * from "./RedactarCartas.vue?vue&type=script&lang=js"
import style0 from "./RedactarCartas.vue?vue&type=style&index=0&id=7784f6ef&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7784f6ef",
  null
  
)

export default component.exports